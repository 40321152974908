<template>
    <div class="step_container">
        <h1 class="step_title">AUTHORIZED PERSON 授权人</h1>
        <a-form-model
            layout="horizontal"
            :rules='rules'
            :model='fourthForm'
            ref='entity_step_fourth'
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <div class="step_question_container">
                <a-form-model-item
                    prop="entity_has_authorize_other"
                    label="Do you authorize others to process your foreign exchange? 您是否授权他人处理您的外汇？"
                >
                    <a-radio-group
                        class="modify_antd_radio"
                        :disabled='disabled'
                        name="entity_has_authorize_other"
                        :value="entity_has_authorize_other"
                        @change="
                            ({ target: { value } }) => {
                                handleChange(
                                    'entity_has_authorize_other',
                                    value
                                )
                            }
                        "
                    >
                        <a-radio :value="true"> Yes 是 </a-radio>
                        <a-radio :value="false"> No 否 </a-radio>
                    </a-radio-group>
                </a-form-model-item>

                <div v-if="entity_has_authorize_other">
                    <a-form-model-item
                        prop="entity_authorize_relationship"
                        :style="GetScreenStyle.width100percent"
                        label="Relationship with the applicant 与申请者的关系"
                    >
                        <a-input
                            :disabled='disabled'
                            @change="
                                ({ target: { value } }) => {
                                    handleChange(
                                        'entity_authorize_relationship',
                                        value
                                    )
                                }
                            "
                            :value="entity_authorize_relationship"
                        >
                        </a-input>
                    </a-form-model-item>
                    <div class="mutiple_form_layout">
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            label="Title 称谓"
                            prop="entity_authorize_title"
                        >
                            <a-select
                                :disabled='disabled'
                                @change="
                                    (value) => {
                                        handleChange(
                                            'entity_authorize_title',
                                            value
                                        )
                                    }
                                "
                                :value="entity_authorize_title"
                            >
                                <a-select-option value="Mr">
                                    Mr
                                </a-select-option>
                                <a-select-option value="Miss">
                                    Miss
                                </a-select-option>
                                <a-select-option value="Ms">
                                    Ms
                                </a-select-option>
                                <a-select-option value="Other">
                                    Other
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            label="First Name 名"
                            prop="entity_authorize_first_name"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_first_name"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_first_name',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            label="Last Name 姓"
                            prop="entity_authorize_last_name"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_last_name"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_last_name',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                    </div>
                    <a-form-model-item
                        prop="entity_authorize_gender"
                        label="Gender 性别"
                    >
                        <a-radio-group
                            class="modify_antd_radio"
                            :disabled='disabled'
                            name="entity_authorize_gender"
                            :value="entity_authorize_gender"
                            @change="
                                ({ target: { value } }) => {
                                    handleChange(
                                        'entity_authorize_gender',
                                        value
                                    )
                                }
                            "
                        >
                            <a-radio value="male"> Male 男 </a-radio>
                            <a-radio value="female"> Female 女 </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <div class="mutiple_form_layout">
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            label="Date of Birth 生日"
                            prop="entity_authorize_dob"
                        >
                            <a-date-picker
                                :disabled='disabled'
                                style="width: 100%"
                                @change="
                                    (e) => {
                                        handleChange(
                                            'entity_authorize_dob',
                                            e.format()
                                        )
                                    }
                                "
                                :inputReadOnly="true"
                                :value="entity_authorize_dob"
                                placeholder="Date of Birth"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                        <BaseCountrySelection
                            :style="GetScreenStyle.width30percent"
                            label="Nationality 国籍(及地区)"
                            prop="entity_authorize_nationality"
                            :disabled="disabled"
                            :selectedVal='entity_authorize_nationality'
                            @handleChange="
                                    (prop, value) => {
                                        handleChange(
                                            'entity_authorize_nationality',
                                            value
                                        )
                                    }
                                "
                            valType='code'
                            >
                        </BaseCountrySelection>
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            label="Place of Birth 出生地"
                            prop="entity_authorize_place_of_birth"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_place_of_birth"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_place_of_birth',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                    </div>
                    <div class="mutiple_form_layout">
                        <BaseCountrySelection
                            :style="GetScreenStyle.width45percent"
                            label="Country of Tax Residence 税务国家"
                            prop="entity_authorize_tax_country"
                            :disabled="disabled"
                            :selectedVal='entity_authorize_tax_country'
                            @handleChange="
                                    (prop,value) => {
                                        handleChange(
                                            prop,
                                            value
                                        )
                                    }
                                "
                            valType='code'
                            >
                            </BaseCountrySelection>
                        <a-form-model-item
                            :style="GetScreenStyle.width45percent"
                            label="Tax Number 税号"
                            prop="entity_authorize_tax_num"
                        >
                            <a-input
                                :disabled='disabled'
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_tax_num',
                                            value
                                        )
                                    }
                                "
                                :value="entity_authorize_tax_num"
                            />
                        </a-form-model-item>
                    </div>
                    <div class="mutiple_form_layout">
                        <a-form-model-item
                            :style="GetScreenStyle.width60percent"
                            prop="entity_authorize_address"
                            label="Residential Address居住地址"
                        >
                            <BaseAddress
                                prop="entity_authorize_address"
                                :disabled="disabled"
                                :value="entity_authorize_address"
                                @handleChange='(prop,value) => {
                                    handleChange(prop, value)
                                }'
                                />
                        </a-form-model-item>
                        <BaseCountrySelection
                            :style="GetScreenStyle.width30percent"
                            label="Country 国家(及地区)"
                            prop="entity_authorize_country"
                            :disabled="disabled"
                            :selectedVal='entity_authorize_country'
                            @handleChange="handleChange"
                            valType='code'
                            >
                            </BaseCountrySelection>
                    </div>
                    <div class="mutiple_form_layout">
                        <a-form-model-item
                            prop="entity_authorize_city"
                            :style="GetScreenStyle.width30percent"
                            label="City 城市"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_city"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_city',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                        <a-form-model-item
                            prop="entity_authorize_suburb"
                            :style="GetScreenStyle.width30percent"
                            label="Suburb 地区"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_suburb"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_suburb',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            prop="entity_authorize_postcode"
                            label="Postcode 邮政编码"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_postcode"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_postcode',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                    </div>
                    <div class="mutiple_form_layout">
                        <BaseCountrySelection
                            :style="GetScreenStyle.width30percent"
                            label="Area Code 区号"
                            prop="entity_authorize_dialcode"
                            :disabled="disabled"
                            :selectedVal='entity_authorize_dialcode'
                            @handleChange='handleChange'
                            valType='dial_code'
                            >
                        </BaseCountrySelection>
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            prop="entity_authorize_phonenum"
                            label="Mobile 移动电话"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_phonenum"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_phonenum',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                        <a-form-model-item
                            prop="entity_authorize_email"
                            :style="GetScreenStyle.width30percent"
                            label="E-mail 电子邮箱"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_email"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_email',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                    </div>
                    <!-- <div class="mutiple_form_layout">
                        <a-form-model-item
                            :style="GetScreenStyle.width45percent"
                            label="Identification type(证件类型)"
                            prop="entity_authorize_identification_type"
                        >
                            <a-select
                                :disabled='disabled'
                                :value="entity_authorize_identification_type"
                                @change="
                                    (value) => {
                                        handleChange(
                                            'entity_authorize_identification_type',
                                            value
                                        )
                                    }
                                "
                            >
                                <a-select-option value="Passport">
                                    Passport(Recommend)
                                </a-select-option>
                                <a-select-option value="birth_certificate">
                                    Birth Certificate
                                </a-select-option>
                                <a-select-option value="business_certificate">
                                    Business Certificate
                                </a-select-option>
                                <a-select-option value="chinese_id_card">
                                    Chinese ID Card
                                </a-select-option>
                                <a-select-option
                                    value="citizenship_certificate"
                                >
                                    Citizenship Certificate
                                </a-select-option>
                                <a-select-option value="eightteen_plus_card">
                                    NZ 18+ Card
                                </a-select-option>
                                <a-select-option value="nz_firearm_license">
                                    NZ Firearm License
                                </a-select-option>
                                <a-select-option value="nz_au_drivers_licence">
                                    NZ/AU Drivers Licence
                                </a-select-option>
                                <a-select-option value="other">
                                    Other
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item
                            :style="GetScreenStyle.width45percent"
                            label="ID number 证件号码"
                            prop="entity_authorize_id_num"
                        >
                            <a-input
                                :disabled='disabled'
                                :value="entity_authorize_id_num"
                                @change="
                                    ({ target: { value } }) => {
                                        handleChange(
                                            'entity_authorize_id_num',
                                            value
                                        )
                                    }
                                "
                            />
                        </a-form-model-item>
                    </div>
                    <div class="mutiple_form_layout">
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            label="Issue Date 签发日期"
                            prop="entity_authorize_issue_date"
                        >
                            <a-date-picker
                                :disabled='disabled'
                                :inputReadOnly="true"
                                style="width: 100%"
                                @change="
                                    (value) => {
                                        handleChange(
                                            'entity_authorize_issue_date',
                                            value.format()
                                        )
                                    }
                                "
                                :value="entity_authorize_issue_date"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                            label="Expiry Date失效日期"
                            prop="entity_authorize_expiry_date"
                        >
                            <a-date-picker
                                :disabled='disabled'
                                :inputReadOnly="true"
                                style="width: 100%"
                                @change="
                                    (value) => {
                                        handleChange(
                                            'entity_authorize_expiry_date',
                                            value.format()
                                        )
                                    }
                                "
                                :value="entity_authorize_expiry_date"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                        <BaseCountrySelection
                            :style="GetScreenStyle.width30percent"
                            label="Issue Country(签发国家)"
                            prop="entity_authorize_issue_country"
                            :disabled="disabled"
                            :selectedVal='entity_authorize_issue_country'
                            @handleChange='handleChange'
                            valType='code'
                            >
                            </BaseCountrySelection>
                    </div> -->
                    <BaseId
                        :disabled='disabled'
                        @FileValidate='validateField'
                        @handleChange="handleChange"
                        :proveChannel="entity_authorize_proveChannel"
                        proveChannel_str="entity_authorize_proveChannel"
                        :proveMethod="entity_authorize_proveMethod"
                        proveMethod_str="entity_authorize_proveMethod"
                        :nz_au_passport_num="
                            entity_authorize_nz_au_passport_num
                        "
                        nz_au_passport_num_str="entity_authorize_nz_au_passport_num"
                        :nz_au_passport_country="
                            entity_authorize_nz_au_passport_country
                        "
                        nz_au_passport_country_str="entity_authorize_nz_au_passport_country"
                        :nz_au_passport_issue_date="entity_authorize_nz_au_passport_issue_date"
                        nz_au_passport_issue_date_str="entity_authorize_nz_au_passport_issue_date"
                        :nz_au_passport_expiry_date="entity_authorize_nz_au_passport_expiry_date"
                        nz_au_passport_expiry_date_str="entity_authorize_nz_au_passport_expiry_date"
                        :nz_au_passport_file="
                            entity_authorize_nz_au_passport_file
                        "
                        nz_au_passport_file_str="entity_authorize_nz_au_passport_file"
                        :nz_au_driver_num="entity_authorize_nz_au_driver_num"
                        nz_au_driver_num_str="entity_authorize_nz_au_driver_num"
                        :nz_au_driver_country="
                            entity_authorize_nz_au_driver_country
                        "
                        nz_au_driver_country_str="entity_authorize_nz_au_driver_country"
                        :nz_au_driver_issue_date="entity_authorize_nz_au_driver_issue_date"
                        nz_au_driver_issue_date_str="entity_authorize_nz_au_driver_issue_date"
                        :nz_au_driver_expiry_date="entity_authorize_nz_au_driver_expiry_date"
                        nz_au_driver_expiry_date_str="entity_authorize_nz_au_driver_expiry_date"
                        :nz_au_driver_front_file="
                            entity_authorize_nz_au_driver_front_file
                        "
                        nz_au_driver_front_file_str="entity_authorize_nz_au_driver_front_file"
                        :nz_au_driver_back_file="
                            entity_authorize_nz_au_driver_back_file
                        "
                        nz_au_driver_back_file_str="entity_authorize_nz_au_driver_back_file"
                        :cn_passport_num="entity_authorize_cn_passport_num"
                        cn_passport_num_str="entity_authorize_cn_passport_num"
                        :cn_passport_country="
                            entity_authorize_cn_passport_country
                        "
                        cn_passport_country_str="entity_authorize_cn_passport_country"
                        :cn_passport_issue_date="entity_authorize_cn_passport_issue_date"
                        cn_passport_issue_date_str="entity_authorize_cn_passport_issue_date"
                        :cn_passport_expiry_date="entity_authorize_cn_passport_expiry_date"
                        cn_passport_expiry_date_str="entity_authorize_cn_passport_expiry_date"
                        :cn_passport_file="entity_authorize_cn_passport_file"
                        cn_passport_file_str="entity_authorize_cn_passport_file"
                        :cn_passport_visa_file="
                            entity_authorize_cn_passport_visa_file
                        "
                        cn_passport_visa_file_str="entity_authorize_cn_passport_visa_file"
                        :cn_identify_num="entity_authorize_cn_identify_num"
                        cn_identify_num_str="entity_authorize_cn_identify_num"
                        :cn_identify_country="
                            entity_authorize_cn_identify_country
                        "
                        cn_identify_country_str="entity_authorize_cn_identify_country"
                        :cn_identify_issue_date="entity_authorize_cn_identify_issue_date"
                        cn_identify_issue_date_str="entity_authorize_cn_identify_issue_date"
                        :cn_identify_expiry_date="entity_authorize_cn_identify_expiry_date"
                        cn_identify_expiry_date_str="entity_authorize_cn_identify_expiry_date"
                        :cn_identify_front_file="
                            entity_authorize_cn_identify_front_file
                        "
                        cn_identify_front_file_str="entity_authorize_cn_identify_front_file"
                        :cn_identify_back_file="
                            entity_authorize_cn_identify_back_file
                        "
                        cn_identify_back_file_str="entity_authorize_cn_identify_back_file"
                        :cn_bank_num="entity_authorize_cn_bank_num"
                        cn_bank_num_str="entity_authorize_cn_bank_num"
                        :cn_bank_country="entity_authorize_cn_bank_country"
                        cn_bank_country_str="entity_authorize_cn_bank_country"
                        :cn_bank_issue_date="entity_authorize_cn_bank_issue_date"
                        cn_bank_issue_date_str="entity_authorize_cn_bank_issue_date"
                        :cn_bank_expiry_date="entity_authorize_cn_bank_expiry_date"
                        cn_bank_expiry_date_str="entity_authorize_cn_bank_expiry_date"
                        :cn_bank_file="entity_authorize_cn_bank_file"
                        cn_bank_file_str="entity_authorize_cn_bank_file"
                        :cn_passport_option_num="
                            entity_authorize_cn_passport_option_num
                        "
                        cn_passport_option_num_str="entity_authorize_cn_passport_option_num"
                        :cn_passport_option_country="
                            entity_authorize_cn_passport_option_country
                        "
                        cn_passport_option_country_str="entity_authorize_cn_passport_option_country"
                        :cn_passport_option_issue_date="entity_authorize_cn_passport_option_issue_date"
                        cn_passport_option_issue_date_str="entity_authorize_cn_passport_option_issue_date"
                        :cn_passport_option_expiry_date="entity_authorize_cn_passport_option_expiry_date"
                        cn_passport_option_expiry_date_str="entity_authorize_cn_passport_option_expiry_date"
                        :cn_passport_option_file="
                            entity_authorize_cn_passport_option_file
                        "
                        cn_passport_option_file_str="entity_authorize_cn_passport_option_file"
                        :cn_passport_visa_option_file="
                            entity_authorize_cn_passport_visa_option_file
                        "
                        cn_passport_visa_option_file_str="entity_authorize_cn_passport_visa_option_file"
                        :other_passport_option_num="
                            entity_authorize_other_passport_option_num
                        "
                        other_passport_option_num_str="entity_authorize_other_passport_option_num"
                        :other_passport_option_country="
                            entity_authorize_other_passport_option_country
                        "
                        other_passport_option_country_str="entity_authorize_other_passport_option_country"
                        :other_passport_option_issue_date="entity_authorize_other_passport_option_issue_date"
                        other_passport_option_issue_date_str="entity_authorize_other_passport_option_issue_date"
                        :other_passport_option_expiry_date="entity_authorize_other_passport_option_expiry_date"
                        other_passport_option_expiry_date_str="entity_authorize_other_passport_option_expiry_date"
                        :other_passport_option_file="
                            entity_authorize_other_passport_option_file
                        "
                        other_passport_option_file_str="entity_authorize_other_passport_option_file"
                        :other_passport_visa_option_file="
                            entity_authorize_other_passport_visa_option_file
                        "
                        other_passport_visa_option_file_str="entity_authorize_other_passport_visa_option_file"
                        :birth_certificate_num="entity_authorize_birth_certificate_num"
                        birth_certificate_num_str="entity_authorize_birth_certificate_num_str"
                        :birth_certificate_country="entity_authorize_birth_certificate_country"
                        birth_certificate_country_str="entity_authorize_birth_certificate_country"
                        :birth_certificate_file="entity_authorize_birth_certificate_file"
                        birth_certificate_file_str="entity_authorize_birth_certificate_file"
                        :nz_citizenship_num="entity_authorize_nz_citizenship_num"
                        nz_citizenship_num_str="entity_authorize_nz_citizenship_num"
                        :nz_citizenship_country="entity_authorize_nz_citizenship_country"
                        nz_citizenship_country_str="entity_authorize_nz_citizenship_country"
                        :nz_citizenship_issue_date="entity_authorize_nz_citizenship_issue_date"
                        nz_citizenship_issue_date_str="entity_authorize_nz_citizenship_issue_date"
                        :nz_citizenship_expiry_date="entity_authorize_nz_citizenship_expiry_date"
                        nz_citizenship_expiry_date_str="entity_authorize_nz_citizenship_expiry_date"
                        :nz_citizenship_front_file="entity_authorize_nz_citizenship_front_file"
                        nz_citizenship_front_file_str="entity_authorize_nz_citizenship_front_file"
                        :nz_citizenship_back_file="entity_authorize_nz_citizenship_back_file"
                        nz_citizenship_back_file_str="entity_authorize_nz_citizenship_back_file"
                        :other_national_id_num="entity_authorize_other_national_id_num"
                        other_national_id_num_str="entity_authorize_other_national_id_num"
                        :other_national_id_country="entity_authorize_other_national_id_country"
                        other_national_id_country_str="entity_authorize_other_national_id_country"
                        :other_national_id_issue_date="entity_authorize_other_national_id_issue_date"
                        other_national_id_issue_date_str="entity_authorize_other_national_id_issue_date"
                        :other_national_id_expiry_date="entity_authorize_other_national_id_expiry_date"
                        other_national_id_expiry_date_str="entity_authorize_other_national_id_expiry_date"
                        :other_national_id_front_file="entity_authorize_other_national_id_front_file"
                        other_national_id_front_file_str="entity_authorize_other_national_id_front_file"
                        :other_national_id_back_file="entity_authorize_other_national_id_back_file"
                        other_national_id_back_file_str="entity_authorize_other_national_id_back_file"
                    ></BaseId>
                    <BaseAddressVertify
                        :disabled='disabled'
                        radio_var="entity_authorize_addressProveType"
                        :address_prove_type="entity_authorize_addressProveType"
                        address_prove_file_list_str="entity_authorize_address_file_list"
                        :address_prove_file_list="
                            entity_authorize_address_file_list
                        "
                        @handleChange="handleChange"
                        @FileValidate="validateField"
                    ></BaseAddressVertify>
                    <div class="claim_text mb-20px">
                        Your signature should be in accordance with the signature either on your Passport or Driver License or other National IDs.<br/>
                        签名请与护照，或者驾照，或者其他身份证件中的任一签名一致。
                    </div>
                    <a-form-model-item
                        ref="self_sign"
                        prop="entity_authorize_sign_key"
                    >
                        <Signature
                            name="entity_authorize_sign_key"
                            :disabled='disabled'
                            class="marginX20"
                            :outsourceSign="self_sign"
                            @upLoadSignKey="handleSignUpload"
                        ></Signature>
                        <AUploadFile
                            v-if="showSecrect"
                            prop="main"
                            @handleFileChange="handleFileList"
                        ></AUploadFile>
                    </a-form-model-item>
                    <div class="claim_text">
                        You can generate a signature link for sending and
                        signing. To confirm the signature after
                        submitting,please refresh this page.
                        生成签名链接用于发送给客户签字，确认签字提交后请
                    </div>
                    <button
                        class="
                            my-2
                            px-2
                            py-1
                            border-black-75
                            shadow-md
                            border
                            rounded-md
                            block
                            fetch_sign
                        "
                        :disabled='disabled'
                        @click="refreshSign"
                    >
                        点击此处
                    </button>
                    <button
                        :disabled='disabled'
                        @click="handleShowGenerate"
                        v-show="get_show_generate_link"
                        class="generate_btn"
                    >
                        Generate the Link 生成链接
                    </button>
                    <div
                        v-show="!get_show_generate_link"
                        class="general_sign_container"
                    >
                        <div class="general_sign_title">
                            Copy link to share 复制链接分享
                        </div>
                        <div class="general_sign_url">
                            {{ getShareLink }}
                        </div>
                        <button
                            :data-clipboard-text="getShareLink"
                            ref="copy_btn"
                            class="copy_link_btn"
                        >
                            Copy Link 复制链接
                        </button>
                        <span
                            v-if="is_copy_success === true"
                            class="copy_success_text"
                            >Successfully copied 已复制成功</span
                        >
                    </div>
                </div>
            </div>
        </a-form-model>
    </div>
</template>

<script>
import BaseId from '@/components/BaseId.vue'
import Signature from '@/components/Signature.vue'
import Clipboard from 'clipboard'
import BaseAddressVertify from '@/components/BaseAddressVertify.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { getAwsPic, fetchSign, uploadSign } from '@/api/common'
import rules from '@/assets/rules/entityFourthRules.js'
import cnFilter from '@/utils/chineseFilter.js'
import BaseCountrySelection from '@/components/BaseCountrySelection'
import BaseAddress from '@/components/BaseAddress.vue'

export default {
  created () {
    this.rules = rules
    if (this.entity_authorize_sign_key) {
      this.fetchSign(this.entity_authorize_sign_key, 'self_sign')
    }
  },
  components: {
    BaseAddressVertify,
    BaseId,
    Signature,
    BaseCountrySelection,
    BaseAddress
  },
  computed: {
    getShareLink () {
      if (this.attributes && this.attributes.username) {
        return `${window.location.host}/signpage/?username=${this.attributes.username}&type=entity_authorize&customer_type=E&token=${encodeURI(JSON.stringify(this.AwsToken))}`
      }
      return 'data Error'
    },
    fourthForm () {
      return this.$store.state.entityStepFour
    },
    entity_authorize_dob_formate () {
      return moment(this.entity_authorize_dob)
    },
    get_show_generate_link () {
      return this.show_generate_link === false
    },
    ...mapState('entityStepFour', ['entity_has_authorize_other', 'entity_authorize_relationship', 'entity_authorize_title', 'entity_authorize_first_name', 'entity_authorize_last_name', 'entity_authorize_gender', 'entity_authorize_dob', 'entity_authorize_nationality', 'entity_authorize_place_of_birth', 'entity_authorize_tax_country', 'entity_authorize_tax_num', 'entity_authorize_address', 'entity_authorize_country', 'entity_authorize_city', 'entity_authorize_suburb', 'entity_authorize_postcode', 'entity_authorize_dialcode', 'entity_authorize_phonenum', 'entity_authorize_email', 'entity_authorize_identification_type', 'entity_authorize_id_num', 'entity_authorize_issue_date', 'entity_authorize_expiry_date', 'entity_authorize_issue_country', 'entity_authorize_addressProveType', 'entity_authorize_address_file_list', 'entity_authorize_proveChannel', 'entity_authorize_proveMethod', 'entity_authorize_nz_au_passport_num',
      'entity_authorize_nz_au_passport_country', 'entity_authorize_nz_au_passport_file', 'entity_authorize_nz_au_driver_num', 'entity_authorize_nz_au_driver_country', 'entity_authorize_nz_au_driver_front_file', 'entity_authorize_nz_au_driver_back_file', 'entity_authorize_cn_passport_num', 'entity_authorize_cn_passport_country', 'entity_authorize_cn_passport_file', 'entity_authorize_cn_passport_visa_file', 'entity_authorize_cn_identify_num', 'entity_authorize_cn_identify_country', 'entity_authorize_cn_identify_front_file', 'entity_authorize_cn_identify_back_file', 'entity_authorize_cn_bank_num', 'entity_authorize_cn_bank_country', 'entity_authorize_cn_bank_file', 'entity_authorize_cn_passport_option_num', 'entity_authorize_cn_passport_option_country', 'entity_authorize_cn_passport_option_file', 'entity_authorize_cn_passport_visa_option_file', 'entity_authorize_other_passport_option_num', 'entity_authorize_other_passport_option_country', 'entity_authorize_other_passport_option_file', 'entity_authorize_other_passport_visa_option_file',
      'entity_authorize_sign_key',
      'entity_authorize_nz_au_passport_issue_date',
      'entity_authorize_nz_au_passport_expiry_date',
      'entity_authorize_nz_au_driver_issue_date',
      'entity_authorize_nz_au_driver_expiry_date',
      'entity_authorize_cn_passport_issue_date', 'entity_authorize_cn_passport_expiry_date', 'entity_authorize_cn_identify_issue_date', 'entity_authorize_cn_identify_expiry_date', 'entity_authorize_cn_bank_issue_date', 'entity_authorize_cn_bank_expiry_date', 'entity_authorize_cn_passport_option_issue_date', 'entity_authorize_cn_passport_option_expiry_date', 'entity_authorize_other_passport_option_issue_date', 'entity_authorize_other_passport_option_expiry_date', 'entity_authorize_birth_certificate_num', 'entity_authorize_birth_certificate_country', 'entity_authorize_birth_certificate_file', 'entity_authorize_nz_citizenship_num', 'entity_authorize_nz_citizenship_country', 'entity_authorize_nz_citizenship_issue_date', 'entity_authorize_nz_citizenship_expiry_date', 'entity_authorize_nz_citizenship_front_file', 'entity_authorize_nz_citizenship_back_file', 'entity_authorize_other_national_id_num', 'entity_authorize_other_national_id_country', 'entity_authorize_other_national_id_issue_date', 'entity_authorize_other_national_id_expiry_date', 'entity_authorize_other_national_id_front_file', 'entity_authorize_other_national_id_back_file'
    ]),
    ...mapGetters('layout', ['GetScreenStyle']),
    ...mapState('countrySelection', ['popularCountryList']),
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    }),
    ...mapState('login', ['AwsToken', 'user', 'attributes'])

  },
  data () {
    return {
      self_sign: null,
      show_generate_link: false,
      is_copy_success: false,
      url: this.getShareLink,
      showSecrect: false,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  watch: {
    entity_authorize_sign_key (newValue) {
      this.fetchSign(newValue, 'self_sign')
    },
    entity_has_authorize_other (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const btnCopy = new Clipboard(this.$refs.copy_btn)
          btnCopy.on('success', (e) => {
            this.is_copy_success = true
            e.clearSelection()
          })
          btnCopy.on('error', e => {
            this.$message.warning('该浏览器不支持自动复制')
            btnCopy.destroy()
          })
        })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.disabled) {
      next()
    } else {
      this.$refs.entity_step_fourth.validate(valid => {
        if (valid) {
          next()
        } else {
          next()
        }
      })
    }
  },
  methods: {

    ...mapActions('entityStepFour', ['handleChangeAction']),
    handleShowGenerate () {
      this.show_generate_link = true
    },
    validateField (field) {
      this.$refs.entity_step_fourth.validateField(field)
    },
    refreshSign () {
      const query = {
        type: 'entity_authorize'
      }
      fetchSign(query).then(res => {
        this.handleChangeAction({ variable: 'entity_authorize_sign_key', value: res.signature })
        this.fetchSign(res.signature, 'self_sign')
      })
    },
    handleChange (variable, value) {
      this.handleChangeAction({ variable, value: cnFilter(value) })
    },
    handleSignUpload (variable, val) {
      this.handleChangeAction({ variable, value: val })
      this.validateField(variable)
    },
    handleFileList (fileList, type) {
      const url = fileList.pop().key
      const query = {
        username: this.attributes.username,
        signature_url: url,
        type
      }
      uploadSign(query).then(() => {
        this.$message.success('上传成功')
      })
    },
    fetchSign (from, x) {
      const query = {
        key: from
      }
      getAwsPic(query).then(res => {
        if (res.url) {
          this[x] = res.url
        }
      })
    }
  }
}
</script>

<style scoped>
.modify_antd_radio >>> .ant-radio-inner {
    width: 26px;
    height: 26px;
}
.modify_antd_radio >>> .ant-radio-inner::after {
    width: 12px;
    left: 6px;
    top: 6px;
    height: 12px;
}
.generate_btn{
  border: 1px solid #ea7840;
  color: #ea7840;
  padding: 10px;
  font-size: 15px;
  font-weight: 300;
  background-color: #fff;
  outline: none;
  margin-top: 5px;
  transition: .2s;
}
.generate_btn:hover{
  background-color:#ea7840;
  color: #fff;
}
.generate_btn:disabled {
    cursor: not-allowed;
}
.general_sign_container{
  margin-top: 10px;
  background: #f8f8f8;
  padding:10px;
}
.general_sign_title{
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.general_sign_url{
  font-weight: 300;
  margin-top:5px;
  white-space: normal;
  word-break: break-all;
}
.copy_link_btn{
  background-color: #ea7840;
  color: #fff;
  padding: 3px;
  font-size: 14px;
  transition: .2s;
  outline: none;
  display: block;
  margin: 5px 0;
}
.copy_link_btn:hover{
  background: #f09f74;
}
.copy_success_text{
  font-weight: 300;
  color: red;
  font-size: 13px;
}
</style>
