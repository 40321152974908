/* eslint-disable standard/no-callback-literal */
const testFile = function (rule, value, cb) {
  if (value) {
    if (value.length <= 0) {
      cb(false)
    } else {
      cb()
    }
  } else {
    cb(false)
  }
}
const emailValidator = {
  required: true, type: 'email', message: 'Please input correct email', trigger: 'blur'
}
const fileValidator = { validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change' }
const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'blur' }

// const testSign = function (rule, value, cb) {
//   console.log(cb)
//   if (value) {
//     cb(true)
//   } else {
//     cb(false)
//   }
// }
export default {
  entity_has_authorize_other: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_authorize_relationship: [
    baseRequirement
  ],
  entity_authorize_title: [
    baseRequirement
  ],
  entity_authorize_first_name: [
    baseRequirement
  ],
  entity_authorize_last_name: [
    baseRequirement

  ],
  entity_authorize_gender: [
    baseRequirement
  ],
  entity_authorize_dob: [
    baseRequirement
  ],
  entity_authorize_nationality: [
    baseRequirement
  ],
  entity_authorize_place_of_birth: [
    baseRequirement
  ],
  entity_authorize_tax_country: [
    baseRequirement
  ],
  entity_authorize_tax_num: [
    baseRequirement
  ],
  entity_authorize_address: [
    baseRequirement
  ],
  entity_authorize_country: [
    baseRequirement
  ],
  entity_authorize_city: [
    baseRequirement
  ],
  entity_authorize_suburb: [
    baseRequirement
  ],
  entity_authorize_postcode: [
    baseRequirement
  ],
  entity_authorize_dialcode: [
    baseRequirement
  ],
  entity_director_gender: [
    baseRequirement
  ],
  entity_authorize_phonenum: [
    baseRequirement
  ],
  entity_authorize_email: [
    emailValidator
  ],
  entity_director_addressProveType: [
    baseRequirement
  ],
  entity_director_id_proveChannel: [
    baseRequirement
  ],
  entity_authorize_identification_type: [
    baseRequirement
  ],
  entity_authorize_id_num: [
    baseRequirement
  ],
  entity_authorize_issue_date: [
    baseRequirement
  ],
  entity_authorize_expiry_date: [
    baseRequirement
  ],
  entity_authorize_issue_country: [
    baseRequirement
  ],
  entity_authorize_addressProveType: [
    baseRequirement
  ],
  entity_authorize_address_file_list: [
    baseRequirement
  ],
  entity_authorize_proveChannel: [
    baseRequirement
  ],
  entity_authorize_proveMethod: [
    { ...fileValidator }
  ],
  entity_authorize_nz_au_passport_num: [
    baseRequirement
  ],
  entity_authorize_nz_au_passport_country: [
    baseRequirement
  ],
  entity_authorize_nz_au_passport_file: [
    baseRequirement
  ],
  entity_authorize_nz_au_driver_num: [
    baseRequirement
  ],
  entity_authorize_nz_au_driver_country: [
    baseRequirement
  ],
  entity_authorize_nz_au_driver_front_file: [
    baseRequirement
  ],
  entity_authorize_nz_au_driver_back_file: [
    baseRequirement
  ],
  entity_authorize_cn_passport_num: [
    baseRequirement
  ],
  entity_authorize_cn_passport_country: [
    baseRequirement
  ],
  entity_authorize_cn_passport_file: [
    baseRequirement
  ],
  entity_authorize_cn_passport_visa_file: [
    baseRequirement
  ],
  entity_authorize_cn_identify_num: [
    baseRequirement
  ],
  entity_authorize_cn_identify_country: [
    baseRequirement
  ],
  entity_authorize_cn_identify_front_file: [
    baseRequirement
  ],
  entity_authorize_cn_identify_back_file: [
    baseRequirement
  ],
  entity_authorize_cn_bank_num: [
    baseRequirement
  ],
  entity_authorize_cn_bank_country: [
    baseRequirement
  ],
  entity_authorize_cn_bank_file: [
    baseRequirement
  ],
  entity_authorize_cn_passport_option_num: [
    baseRequirement
  ],
  entity_authorize_cn_passport_option_country: [
    baseRequirement
  ],
  entity_authorize_cn_passport_option_file: [
    baseRequirement
  ],
  entity_authorize_cn_passport_visa_option_file: [
    baseRequirement
  ],
  entity_authorize_other_passport_option_num: [
    baseRequirement
  ],
  entity_authorize_other_passport_option_country: [
    baseRequirement
  ],
  entity_authorize_other_passport_option_file: [
    baseRequirement
  ],
  entity_authorize_other_passport_visa_option_file: [
    baseRequirement
  ],
  entity_authorize_sign_key: [
    baseRequirement
  ]
}
